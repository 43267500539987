import { Grid, LoadingOverlay, Paper, Text, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useGetV1TranscriptsId } from '../../api';

export function View() {
  const { transcriptId } = useParams();

  const { data, error, isLoading } = useGetV1TranscriptsId(String(transcriptId), {
    query: { enabled: Boolean(transcriptId) },
  });

  if (isLoading || !data) {
    return <LoadingOverlay visible={true} />;
  }

  if (error) {
    return <h1>{error.message}</h1>;
  }

  return (
    <Grid gutter="md">
      <Grid.Col span={4}>
        <Paper p="xl" radius="ms">
          <Title order={1}>{data.body.id}</Title>
          <Text my="sm">
            <strong>Level</strong>: {data.body.level}
          </Text>
          <Text my="sm">
            <strong>Status</strong>: {data.body.status}
          </Text>
          <Text my="sm">
            <strong>Duration</strong>: {data.body.duration_in_seconds}s
          </Text>
          {data.body.metadata !== null && (
            <Text>
              <pre>{JSON.stringify(data.body.metadata, null, 2)}</pre>
            </Text>
          )}
        </Paper>
      </Grid.Col>
      <Grid.Col span={8}>
        {data.body.monologues?.map((monologue) => (
          <Paper key={monologue.speaker} p="md" my="sm" radius="sm">
            <Text>
              <strong>Speaker</strong>: {monologue.speaker}
            </Text>
            <Text>
              <strong>Time</strong>: {monologue.start}
            </Text>
            <Text>{monologue.text}</Text>
          </Paper>
        ))}
      </Grid.Col>
    </Grid>
  );
}
