import { Table } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { useCallback } from 'react';
import { PaginatedVecForBot, useGetV1Bots } from '../../api';
import { Loading } from '../../components/Loading';
import { Row } from './Row';

export function List() {
  const queryClient = useQueryClient();
  const bots = useGetV1Bots();

  const onDeleteSuccess = useCallback(
    (_data: unknown, variables: { id: string }) => {
      queryClient.setQueryData(
        bots.queryKey,
        ({
          body,
          headers,
        }: {
          body: PaginatedVecForBot;
          headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
        }) => ({
          headers,
          body: { ...body, items: body.items.filter((i) => i.id !== variables.id) },
        }),
      );
    },
    [bots.queryKey],
  );

  if (bots.isLoading) return <Loading />;
  if (bots.isError) return <h1>{bots.error?.message}</h1>;
  if (!bots.isSuccess) return <h1>Not ready</h1>;

  return (
    <Table.ScrollContainer minWidth={800}>
      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Meeting URL</Table.Th>
            <Table.Th>Join At</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {bots.data.body.items.map((bot) => (
            <Row key={bot.id} bot={bot} onDeleteSuccess={onDeleteSuccess} />
          ))}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
