import { Anchor, NumberFormatter, Table } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useGetV1Transcripts } from '../../api';
import { Loading } from '../../components/Loading';

export function List() {
  const transcripts = useGetV1Transcripts({ per_page: 100 });

  if (transcripts.isLoading) return <Loading />;
  if (transcripts.isError) return <h1>{transcripts.error?.message}</h1>;
  if (!transcripts.isSuccess) return <h1>Not ready</h1>;

  return (
    <Table.ScrollContainer minWidth={800}>
      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Level</Table.Th>
            <Table.Th>Duration (minutes)</Table.Th>
            <Table.Th>Date</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {transcripts.data.body.items.map(
            ({ id, status, duration_in_seconds, level, recorded_at, created_at }) => (
              <Table.Tr key={id}>
                <Table.Td>
                  <Anchor component={Link} fz="sm" to={id}>
                    {id}
                  </Anchor>
                </Table.Td>
                <Table.Td>{status}</Table.Td>
                <Table.Td>{level}</Table.Td>
                <Table.Td>
                  {typeof duration_in_seconds === 'number' ? (
                    <NumberFormatter value={duration_in_seconds / 60} decimalScale={2} />
                  ) : (
                    0
                  )}
                </Table.Td>
                <Table.Td>{recorded_at ?? created_at}</Table.Td>
              </Table.Tr>
            ),
          )}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
