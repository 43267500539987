import { Route, Routes } from 'react-router-dom';
import { View } from './View';
import { List } from './List';

export function Bots() {
  return (
    <>
      <h1>Bots</h1>
      <Routes>
        <Route path="" element={<List />} />
        <Route path=":botId" element={<View />} />
      </Routes>
    </>
  );
}
