/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Skriver
 * Skriver is your trusted companion for transcribing audio into text in a machine-friendly format.

# Features

- Transcribe audio files into text
- Join meetings and transcribe them
- Connect to calendars to join and transcribe meetings automatically

# Quick Start

## Step 1: Create a Skriver Account

Head to [auth.skriver.app](https://auth.skriver.app/signup) and register for a free account. You
will be redirected to [dash.skriver.app](https://dash.skriver.app).

## Step 2: Create your API Keys

Once logged int, click on your username and then "Organization API Keys".

> 🚧 Store the keys somewhere safe.
>
> Note that Skriver will make your API key available only once. Hence make sure to immediately copy
> and save the key in a safe and secure location.
>
> Your key is used to authenticate into our APIs and is hence highly sensitive. Please make sure to
> keep the keys in a secure environment. In the event of a compromise, you can immediately revoke
> key
> access from our dashboard and generate a new key.

## Step 3: Transcribe your first audio

You are now ready to query our APIs.

```curl
curl --request POST \
    --url 'https://api.skriver.app/v1/transcripts/remote' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "media_url": "https://assets.skriver.app/examples/interview_speech-analytics.wav",
        "level": "basic",
        "recorded_at": "2023-12-24T10:00:00.000Z",
        "metadata": { "foo": "bar" }
    }'
```

This endpoint will return a response like the following:

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "queued",
    "monologues": null,
    "duration_in_seconds": null,
    "metadata": {
        "foo": "bar"
    },
    "speakers": null,
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Use the `id` to monitor the status of the transcription by calling the `GET` endpoint

```curl
curl --request GET \
     --url 'https://api.skriver.app/transcripts/018c00cf-076c-7c01-bcc1-dad2e8074bc8' \
     --header 'X-Api-Key: <API_KEY>'
```

You will get a response that contains the transcription text

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "completed",
    "monologues": [
        {
            "start": 3.1999998,
            "end": 29.855,
            "speaker": 0,
            "text": "Some transcribed text here"
        }
    ],
    "duration_in_seconds": 33958.688,
    "metadata": {
        "foo": "bar"
    },
    "speakers": [
        "0"
    ],
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Congratulations! You have now converted an audio file into text.

## Step 4: Schedule a bot to join your meeting

You can now schedule a bot to join your meeting and transcribe it. Using the API key, you can create
a "bot"

```curl
curl --request GET \
    --url 'https://api.skriver.app/v1/bots/' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "bot_name": "C3-P0",
        "meeting_url": "https://meet.google.com/abc-defg-hij",
        "join_at": "2024-03-28T10:00:00Z",
        "metadata": { "foo": "bar" }
    }'
```

 * OpenAPI spec version: 0.2.16
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  GetV1TranscriptsParams,
  PaginatedVecForTranscript,
  PostV1TranscriptsLocalParams,
  RemoteTranscriptRequest,
  Transcript,
  UpdateTranscriptRequest,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

/**
 * @summary List all transcripts
 */
export const useGetV1TranscriptsHook = () => {
  const getV1Transcripts = useCustomInstance<PaginatedVecForTranscript>();

  return (params?: GetV1TranscriptsParams, signal?: AbortSignal) => {
    return getV1Transcripts({ url: `/v1/transcripts/`, method: 'GET', params, signal });
  };
};

export const getGetV1TranscriptsQueryKey = (params?: GetV1TranscriptsParams) => {
  return [`/v1/transcripts/`, ...(params ? [params] : [])] as const;
};

export const useGetV1TranscriptsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: GetV1TranscriptsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsHook>>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1TranscriptsQueryKey(params);

  const getV1Transcripts = useGetV1TranscriptsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsHook>>>> = ({
    signal,
  }) => getV1Transcripts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1TranscriptsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsHook>>>
>;
export type GetV1TranscriptsQueryError = ErrorType<unknown>;

/**
 * @summary List all transcripts
 */
export const useGetV1Transcripts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: GetV1TranscriptsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsHook>>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetV1TranscriptsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Upload a local file to be transcribed. The file should be sent as an application/octet-stream
request.

If your file is over 10MB, you will need to use
the [/remote](/docs#tag/Transcripts/paths/~1v1~1transcripts~1remote/post) endpoint.

 * @summary Transcribe a local file
 */
export const usePostV1TranscriptsLocalHook = () => {
  const postV1TranscriptsLocal = useCustomInstance<Transcript>();

  return (params?: PostV1TranscriptsLocalParams) => {
    return postV1TranscriptsLocal({ url: `/v1/transcripts/local`, method: 'POST', params });
  };
};

export const usePostV1TranscriptsLocalMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsLocalHook>>>,
    TError,
    { params?: PostV1TranscriptsLocalParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsLocalHook>>>,
  TError,
  { params?: PostV1TranscriptsLocalParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postV1TranscriptsLocal = usePostV1TranscriptsLocalHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsLocalHook>>>,
    { params?: PostV1TranscriptsLocalParams }
  > = (props) => {
    const { params } = props ?? {};

    return postV1TranscriptsLocal(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1TranscriptsLocalMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsLocalHook>>>
>;

export type PostV1TranscriptsLocalMutationError = ErrorType<unknown>;

/**
 * @summary Transcribe a local file
 */
export const usePostV1TranscriptsLocal = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsLocalHook>>>,
    TError,
    { params?: PostV1TranscriptsLocalParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsLocalHook>>>,
  TError,
  { params?: PostV1TranscriptsLocalParams },
  TContext
> => {
  const mutationOptions = usePostV1TranscriptsLocalMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Transcribe a remote file
 */
export const usePostV1TranscriptsRemoteHook = () => {
  const postV1TranscriptsRemote = useCustomInstance<Transcript>();

  return (remoteTranscriptRequest: RemoteTranscriptRequest) => {
    return postV1TranscriptsRemote({
      url: `/v1/transcripts/remote`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: remoteTranscriptRequest,
    });
  };
};

export const usePostV1TranscriptsRemoteMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsRemoteHook>>>,
    TError,
    { data: RemoteTranscriptRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsRemoteHook>>>,
  TError,
  { data: RemoteTranscriptRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postV1TranscriptsRemote = usePostV1TranscriptsRemoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsRemoteHook>>>,
    { data: RemoteTranscriptRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1TranscriptsRemote(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1TranscriptsRemoteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsRemoteHook>>>
>;
export type PostV1TranscriptsRemoteMutationBody = RemoteTranscriptRequest;
export type PostV1TranscriptsRemoteMutationError = ErrorType<unknown>;

/**
 * @summary Transcribe a remote file
 */
export const usePostV1TranscriptsRemote = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsRemoteHook>>>,
    TError,
    { data: RemoteTranscriptRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostV1TranscriptsRemoteHook>>>,
  TError,
  { data: RemoteTranscriptRequest },
  TContext
> => {
  const mutationOptions = usePostV1TranscriptsRemoteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a single transcript
 */
export const useGetV1TranscriptsIdHook = () => {
  const getV1TranscriptsId = useCustomInstance<Transcript>();

  return (id: string, signal?: AbortSignal) => {
    return getV1TranscriptsId({ url: `/v1/transcripts/${id}`, method: 'GET', signal });
  };
};

export const getGetV1TranscriptsIdQueryKey = (id: string) => {
  return [`/v1/transcripts/${id}`] as const;
};

export const useGetV1TranscriptsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsIdHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsIdHook>>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1TranscriptsIdQueryKey(id);

  const getV1TranscriptsId = useGetV1TranscriptsIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsIdHook>>>
  > = ({ signal }) => getV1TranscriptsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsIdHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1TranscriptsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsIdHook>>>
>;
export type GetV1TranscriptsIdQueryError = ErrorType<unknown>;

/**
 * @summary Get a single transcript
 */
export const useGetV1TranscriptsId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsIdHook>>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1TranscriptsIdHook>>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetV1TranscriptsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update a transcript
 */
export const usePutV1TranscriptsIdHook = () => {
  const putV1TranscriptsId = useCustomInstance<Transcript>();

  return (id: string, updateTranscriptRequest: UpdateTranscriptRequest) => {
    return putV1TranscriptsId({
      url: `/v1/transcripts/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateTranscriptRequest,
    });
  };
};

export const usePutV1TranscriptsIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1TranscriptsIdHook>>>,
    TError,
    { id: string; data: UpdateTranscriptRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutV1TranscriptsIdHook>>>,
  TError,
  { id: string; data: UpdateTranscriptRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putV1TranscriptsId = usePutV1TranscriptsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutV1TranscriptsIdHook>>>,
    { id: string; data: UpdateTranscriptRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putV1TranscriptsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1TranscriptsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutV1TranscriptsIdHook>>>
>;
export type PutV1TranscriptsIdMutationBody = UpdateTranscriptRequest;
export type PutV1TranscriptsIdMutationError = ErrorType<unknown>;

/**
 * @summary Update a transcript
 */
export const usePutV1TranscriptsId = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1TranscriptsIdHook>>>,
    TError,
    { id: string; data: UpdateTranscriptRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePutV1TranscriptsIdHook>>>,
  TError,
  { id: string; data: UpdateTranscriptRequest },
  TContext
> => {
  const mutationOptions = usePutV1TranscriptsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * ## Usage

### Level 3

```json
{
    "method": "DELETE",
    "url": "/v1/transcripts/:id"
}
```

For when deleting is your thing

 * @summary Delete a transcript
 */
export const useDeleteV1TranscriptsIdHook = () => {
  const deleteV1TranscriptsId = useCustomInstance<Transcript>();

  return (id: string) => {
    return deleteV1TranscriptsId({ url: `/v1/transcripts/${id}`, method: 'DELETE' });
  };
};

export const useDeleteV1TranscriptsIdMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1TranscriptsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteV1TranscriptsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteV1TranscriptsId = useDeleteV1TranscriptsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1TranscriptsIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteV1TranscriptsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteV1TranscriptsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteV1TranscriptsIdHook>>>
>;

export type DeleteV1TranscriptsIdMutationError = ErrorType<unknown>;

/**
 * @summary Delete a transcript
 */
export const useDeleteV1TranscriptsId = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1TranscriptsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useDeleteV1TranscriptsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = useDeleteV1TranscriptsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
