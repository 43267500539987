import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { createTheme, LoadingOverlay, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { RedirectToLogin, RequiredAuthProvider } from '@propelauth/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';

const container = document.getElementById('app');
if (container === null) {
  throw new Error('element #app not found');
}

const { AUTH_URL } = process.env;

if (AUTH_URL === undefined) {
  throw new Error('AUTH_URL not defined');
}

function getActiveOrg() {
  return localStorage.getItem('ActiveOrg');
}

const queryClient = new QueryClient();

const theme = createTheme({
  /** Your theme override here */
});

const root = createRoot(container);
root.render(
  <MantineProvider defaultColorScheme="dark" theme={theme}>
    <Notifications position="top-right" />
    <RequiredAuthProvider
      authUrl={AUTH_URL}
      displayWhileLoading={<LoadingOverlay />}
      displayIfLoggedOut={<RedirectToLogin />}
      getActiveOrgFn={getActiveOrg}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <StrictMode>
            <App />
          </StrictMode>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </RequiredAuthProvider>
  </MantineProvider>,
);
