import { Divider, Table } from '@mantine/core';
import { FormErrors } from '@mantine/form/lib/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { CustomWord, useGetV1CustomWords } from '../../api';
import { Loading } from '../../components/Loading';
import { Form } from './Form';
import { Row } from './Row';

export function List() {
  const queryClient = useQueryClient();
  const customWords = useGetV1CustomWords();

  const onCreateSuccess = useCallback(
    ({ body: customWord }: { body: CustomWord }) => {
      queryClient.setQueryData(customWords.queryKey, (oldData: CustomWord[]) => [
        ...oldData,
        customWord,
      ]);
    },
    [customWords.queryKey],
  );

  const onCreateError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  const onDeleteSuccess = useCallback(
    ({ body: customWord }: { body: CustomWord }) => {
      queryClient.setQueryData(customWords.queryKey, (oldData: CustomWord[]) =>
        oldData.filter((i) => i.id !== customWord.id),
      );
    },
    [customWords.queryKey],
  );

  const onDeleteError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  const onUpdateSuccess = useCallback(
    ({ body: customWord }: { body: CustomWord }) => {
      queryClient.setQueryData(customWords.queryKey, (oldData: CustomWord[]) => [
        ...oldData.map((i) => {
          if (i.id === customWord.id) {
            return customWord;
          }

          return i;
        }),
      ]);
    },
    [customWords.queryKey],
  );

  const onUpdateError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  if (customWords.isLoading) return <Loading />;
  if (customWords.isError) return <h1>{customWords.error?.message}</h1>;
  if (!customWords.isSuccess) return <h1>Not ready</h1>;

  return (
    <>
      <Form onSuccess={onCreateSuccess} onError={onCreateError} />
      <Divider my="sm" />
      <Table.ScrollContainer minWidth={800}>
        <Table verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Text</Table.Th>
              <Table.Th>Enabled</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {customWords.data.body.items.map((customWord) => (
              <Row
                key={customWord.id}
                customWord={customWord}
                onDeleteError={onDeleteError}
                onDeleteSuccess={onDeleteSuccess}
                onUpdateSuccess={onUpdateSuccess}
                onUpdateError={onUpdateError}
              />
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
}
