import { Anchor, Button, Table } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Bot, useDeleteV1BotsId } from '../../api';

interface Props {
  bot: Bot;
  onDeleteSuccess: (data: unknown, variables: { id: string }, context: unknown) => unknown;
}

export function Row({ bot, onDeleteSuccess }: Readonly<Props>) {
  const deleteBot = useDeleteV1BotsId({
    mutation: { onSuccess: onDeleteSuccess },
  });

  const onDelete = useCallback(() => deleteBot.mutate({ id: bot.id }), [deleteBot, bot]);

  return (
    <Table.Tr>
      <Table.Td>
        <Anchor component={Link} to={bot.id}>
          {bot.id}
        </Anchor>
      </Table.Td>
      <Table.Td>{bot.status}</Table.Td>
      <Table.Td>{bot.meeting_url}</Table.Td>
      <Table.Td>{bot.join_at}</Table.Td>
      <Table.Td>
        <Button color="red" size="sm" onClick={onDelete}>
          <IconTrash />
        </Button>
      </Table.Td>
    </Table.Tr>
  );
}
