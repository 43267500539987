import { useOrgHelper } from '@propelauth/react';
import { Loading } from './components/Loading';

export function SelectOrg() {
  const { orgHelper, loading } = useOrgHelper();
  if (loading || !orgHelper) {
    return <Loading />;
  }
  const [org] = orgHelper.getOrgs();

  if (org) {
    localStorage.setItem('ActiveOrg', org.orgId);
    document.location.reload();
    return <div>Selecting {org.orgName} org</div>;
  }

  return <div>You have no orgs</div>;
}
