import { Alert, Button, Loader, Select, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';
import { useCallback, useEffect } from 'react';
import { z } from 'zod';
import { PutV1SettingsMutationBody, useGetV1Settings, usePutV1Settings } from '../api';

export const schema = z.object({
  name: z.string(),
  transcription_level: z.enum(['basic', 'premium']),
  zoom_client_id: z.string().nullable(),
  zoom_client_secret: z.string().nullable(),
});

export function Settings() {
  const settings = useGetV1Settings();
  const updateSettings = usePutV1Settings({
    mutation: {
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (error) => console.error(error),
    },
  });

  const form = useForm<PutV1SettingsMutationBody>({ validate: zodResolver(schema) });
  const handleSubmit = useCallback(
    (values: PutV1SettingsMutationBody) => updateSettings.mutate({ data: values }),
    [updateSettings.mutate],
  );

  useEffect(() => {
    if (settings.data?.body) {
      form.setValues({
        name: settings.data.body.name,
        transcription_level: settings.data.body.transcription_level,
      });
      form.resetDirty();
    }
  }, [settings.data?.body]);

  if (settings.isLoading) return <Loader />;

  return (
    <>
      {updateSettings.error && (
        <Alert
          withCloseButton
          onClose={() => updateSettings.reset()}
          variant="outline"
          color="red"
          title="Failed to save settings"
          icon={<IconInfoCircle />}
        >
          {updateSettings.error.message}
        </Alert>
      )}

      {updateSettings.isSuccess && (
        <Alert
          withCloseButton
          onClose={() => updateSettings.reset()}
          variant="outline"
          color="green"
          title="Success"
          icon={<IconInfoCircle />}
        >
          Settings saved
        </Alert>
      )}

      <form onSubmit={form.onSubmit(handleSubmit, console.error)}>
        <TextInput
          mt="md"
          label="Name"
          placeholder="ABC Inc."
          {...form.getInputProps('name')}
          value={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            form.getInputProps('name').value ?? settings.data?.body.name ?? ''
          }
        />
        <Select
          mt="md"
          label="Default Transcription Level"
          description="Choose the default transcription level for new transcripts. This can be overriden per transcript."
          placeholder="ABC Inc."
          {...form.getInputProps('transcription_level')}
          data={[
            { label: 'Basic', value: 'basic' },
            { label: 'Premium', value: 'premium' },
          ]}
          value={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            form.getInputProps('transcription_level').value ??
            settings.data?.body.transcription_level ??
            ''
          }
        />
        <Button mt="md" type="submit">
          Save
        </Button>
      </form>
    </>
  );
}
