import { Alert, Button, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';
import { useCallback } from 'react';
import { z } from 'zod';
import { PostV1ZoomJoinMutationBody, usePostV1ZoomJoin } from '../api';

export const schema = z.object({
  display_name: z.string(),
  join_url: z.string(),
});

export function ZoomBot() {
  const joinMeeting = usePostV1ZoomJoin({
    mutation: {
      onSuccess: (data) => console.log(data),
      onError: (error) => console.error(error),
    },
  });

  const form = useForm<PostV1ZoomJoinMutationBody>({
    initialValues: {
      display_name: '',
      join_url: '',
    },
    validate: zodResolver(schema),
  });

  const handleSubmit = useCallback(
    (values: PostV1ZoomJoinMutationBody) =>
      joinMeeting.mutate({
        data: { display_name: values.display_name, join_url: values.join_url },
      }),
    [joinMeeting.mutate],
  );

  return (
    <>
      {joinMeeting.error && (
        <Alert
          withCloseButton
          onClose={() => joinMeeting.reset()}
          variant="outline"
          color="red"
          title="Failed to create zoom bot"
          icon={<IconInfoCircle />}
        >
          {joinMeeting.error.message}
        </Alert>
      )}

      {joinMeeting.isSuccess && (
        <Alert
          withCloseButton
          onClose={() => joinMeeting.reset()}
          variant="outline"
          color="green"
          title="Success"
          icon={<IconInfoCircle />}
        >
          A bot will soon join the meeting
        </Alert>
      )}

      <form onSubmit={form.onSubmit(handleSubmit, console.error)}>
        <TextInput
          mt="md"
          label="Bot Name"
          placeholder="My Assistant"
          {...form.getInputProps('display_name')}
        />
        <TextInput
          mt="md"
          label="Join URL"
          placeholder="https://zoom.us/j/123?pwd=456"
          {...form.getInputProps('join_url')}
        />

        <Button mt="md" type="submit">
          Join Meeting
        </Button>
      </form>
    </>
  );
}
