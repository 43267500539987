import { Button, Group, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import { useCallback, useMemo } from 'react';
import { CreateCustomWordRequest, CustomWord, usePostV1CustomWords } from '../../api';
import { schema } from './schema';

interface FormValues {
  text: string;
}

interface Props {
  onSuccess: (
    data: { body: CustomWord },
    variables: { data: CreateCustomWordRequest },
    context: unknown,
  ) => unknown;
  onError: (
    error: FormErrors,
    variables: { data: CreateCustomWordRequest },
    context: unknown,
  ) => unknown;
}

export function Form({ onSuccess, onError }: Readonly<Props>) {
  const customWordMutation = usePostV1CustomWords({
    mutation: { onSuccess, onError },
  });

  const handleSubmit = useCallback(
    (values: FormValues) =>
      customWordMutation.mutate({ data: { text: values.text, enabled: true } }),
    [customWordMutation.mutate],
  );

  const defaultValues = useMemo<FormValues>(() => ({ text: '' }), []);

  const form = useForm<FormValues>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit, console.error)}>
      <Group mt="md" align="center">
        <TextInput placeholder="Text or Phrase" {...form.getInputProps('text')} />

        <Button type="submit">New Custom Word</Button>
      </Group>
    </form>
  );
}
